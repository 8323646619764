import PropTypes from "prop-types";
import React from "react";

import Item from "./Item";

const Blog = props => {
  const { posts, theme } = props;

  return (
    <React.Fragment>
      <section className="postlist">
        {posts.map((post, index) => {
          const {
            node,
            node: {
              fields: { slug }
            }
          } = post;
          return <Item key={slug} post={node} theme={theme} index={index}/>;
        })}
      </section>

      {/* --- STYLES --- */}
      {/*language=SCSS*/}
      <style jsx>{`

        @above tablet {
        }
        @above desktop {
        }
      `}</style>
    </React.Fragment>
  );
};

Blog.propTypes = {
  posts: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired
};

export default Blog;
