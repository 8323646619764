import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

const Meta = props => {
  const { theme, tags, date, slug } = props;

  const dateObj = moment(date);
  const formattedDate = dateObj.format("MMMM Do, YYYY");

  let datePadding = "0";
  if (date) {
    datePadding = "8px";
  }

  return (
    <React.Fragment>
      <ul className="meta">
        {date ? (
          <li className="date">
            <a href={slug}>{formattedDate}</a> {tags ? "•" : null}
          </li>
        ) : null}
        {tags ? <li className="taglead">IN</li> : null}
        {tags
          ? tags.map((tag, index) => {
              let tagUrl = "/tag/" + tag;
              return (
                <li key={tag} className="category">
                  <a href={tagUrl}>{tag}</a> {index !== tags.length - 1 ? "," : null}
                </li>
              );
            })
          : null}
      </ul>

      {/* --- STYLES --- */}
      {/*language=SCSS*/}
      <style jsx>{`
        .meta {
          overflow: hidden;
          padding: 0;
          margin: 0;
          list-style: none;

          li {
            display: block;

            &:last-child:before {
              content: "";
            }

            &:nth-child(2) a {
              padding-right: 0;
            }
          }

          .date,
          .readtime,
          .issticky,
          .category,
          .taglead {
            margin-top: 3px;
            text-transform: uppercase;
            color: #b5b5b5;
            font-family: ${theme.font.reidweb.subheader};
            font-size: 15px;
            letter-spacing: -1px;
            float: left;

            a {
              color: #b5b5b5;
              letter-spacing: -1px;
              display: inline-block;

              &:hover {
                color: #5c5c5c;
              }
            }
          }

          .category {
            a {
              padding-left: 6px;
              padding-right: 0;
            }
          }

          .date {
            a {
              padding-right: 8px;
            }
          }

          .taglead {
            padding-left: ${datePadding};
          }

          .issticky {
            display: none;
            color: #b5b5b5;

            i {
              margin-left: 10px;
            }
          }

          .title:after {
            display: block;
            content: "";
            clear: both;
            height: 4px;
            width: 15%;
            background: #6a6a6a;
            margin: 30px auto 0 auto;
          }
        }

        .author {
          display: inline-block;
          font-family: ${theme.font.reidweb.subheader};
          font-size: 15px;
          letter-spacing: -0.5px;
          color: #b5b5b5;

          &:hover {
            color: #7a7b8e;
          }

          img {
            width: 25px;
            height: 25px;
            border-radius: 50px;
            display: inline-block;
            vertical-align: -7px;
            margin-right: 15px;
          }
        }

        @media (max-width: 490px) {
          .meta .readtime {
            display: none;
          }
        }

        @media (max-width: 370px) {
          .meta {
            .date,
            .category,
            .taglead {
              display: none;
            }

            .category {
              float: none;
              margin: 0 auto;
            }
          }
        }

        @from-width tablet {
        }
        @from-width desktop {
        }
      `}</style>
    </React.Fragment>
  );
};

Meta.propTypes = {
  theme: PropTypes.object.isRequired,
  author: PropTypes.string.isRequired,
  tags: PropTypes.array,
  date: PropTypes.string,
  slug: PropTypes.string.isRequired
};

export default Meta;
