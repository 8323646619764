import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Meta from "./Meta";

const Item = props => {
  const {
    theme,
    post: {
      excerpt,
      fields: { slug, prefix },
      frontmatter: {
        title,
        subtitle,
        tags,
        author,
        date,
        icon,
        cover: {
          children: [{ fluid }]
        }
      }
    },
    index
  } = props;

  let postIcon = "";

  let elementClasses = [];
  elementClasses = "postitem post";
  if (index === 1) {
    elementClasses += " first";
  }
  if (index % 2 !== 0) {
    elementClasses += " even";
  }
  if (icon) {
    elementClasses += " iconBg";
    postIcon = icon.childImageSharp.bgDesktop.src;
  }
  return (
    <React.Fragment>
      <section className={elementClasses}>
        <div className="wrapper" key={slug}>
          <a href="url" aria-label={title} className="content postimage" />
          <h2 className="title">
            <a href={slug}>{title}</a>
          </h2>
          <Meta theme={theme} author={author} tags={tags} date={date} slug={slug} />
          <p className="excerpt">{subtitle || excerpt}</p>
        </div>
      </section>

      {/* --- STYLES --- */}
      {/*language=SCSS*/}
      <style jsx>{`
        .even {
          background: #f9f9f9;
          background-repeat: no-repeat;
          background-position: center;
        }

        .postitem {
          padding: 80px 0;
          background-repeat: no-repeat;
          background-position: center;

          .wrapper {
            max-width: 700px;
            width: 90%;
            margin: 0 auto;
          }

          h1,
          h2 {
            margin: 0;
            margin-bottom: 20px;
            font-family: ${theme.font.reidweb.header};
            font-size: 48px;
            font-weight: 600;
            line-height: 62px;
            letter-spacing: -1px;
          }

          h1 a,
          h2 a {
            display: block;
            color: #373737;

            &:hover {
              color: #5e5e5e;
            }
          }

          .excerpt {
            margin: 20px 0 0 0;
            font-family: ${theme.font.reidweb.article.body};
            font-size: 19px;
            font-weight: 400;
            line-height: 35px;
            color: #62646c;
            color: rgba(35, 37, 40, 0.7);
            overflow: hidden;
          }

          &.post {
            &.iconBg {
              background-image: url(${postIcon});
            }
          }
          @media (max-width: 750px) {
            padding: 10% 0;

            h2 {
              margin: 40px 0;
              font-size: 38px;
              font-weight: 600;
              line-height: 52px;
            }
          }

          @media (max-width: 490px) {
            h2 {
              margin: 30px 0;
              font-size: 32px;
              line-height: 46px;
            }

            .excerpt {
              margin: 30px 0;
              font-size: 17px;
              line-height: 28px;
            }
          }

          @media (max-width: 370px) {
            text-align: center;

            h2 {
              margin: 30px 0;
              font-size: 28px;
              line-height: 42px;
            }

            .excerpt {
              display: none;
              font-size: 16px;
            }

            .meta .category {
              float: none;
              margin: 0 auto;
            }

            &.post {
              background-image: none;
            }
          }
        }

        @from-width tablet {
        }
        @from-width desktop {
        }
      `}</style>
    </React.Fragment>
  );
};

Item.propTypes = {
  post: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default Item;
